import FormItem, { SelectOptionsType } from '@/components/form-item/FormItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { WebsiteLoader } from '@/components/layout/WebsiteLoader'
import { useApplicationQuery, useApplicationTypesQuery, useCreateApplicationMutation } from '@/graphql'
import { getTitleWithIndex } from '@/helpers/getApplicationTitleWithIndex'
import { Col, Form, message, Row } from 'antd'
import moment from 'moment'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const DuplicateApplication: FC = () => {
  const { setIsModalOpen, currentId, setTriggerRefetch } = useGeneralContext()
  const { data, loading: appDataLoading } = useApplicationQuery({
    variables: { id: currentId },
    fetchPolicy: globalFetchPolicy,
  })
  const {data: appTypesData, loading: appTypesLoading} = useApplicationTypesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const appData = data?.application?.data as ApplicationEntity
  const approvalSet = appData?.attributes?.approvals?.data || [] as ApprovalEntity[]
  const appTypes = appTypesData?.applicationTypes?.data as ApplicationTypeEntity[]

  const [createApplication, { loading }] = useCreateApplicationMutation()
  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onDuplicate = async (values: {applicationType: string, applicationApprovals: 'true'}) => {
    const existingRequiredDocuments = appData?.attributes?.requiredDocuments as ComponentDataRequiredDocuments[]

    const requiredDocCopy = existingRequiredDocuments?.map(it => ({
      docTypeName: it?.docTypeName,
      description: it?.description,
      documents: it?.documents?.data?.map(item => item?.id),
    } as ComponentDataRequiredDocumentsInput))

    await createApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          agentsInfo: appData?.attributes?.agentsInfo?.map(it => ({assignStatus: it?.assignStatus, agent: it?.agentProfile?.data?.id})),
          applicationLetter: appData?.attributes?.applicationLetter,
          applicationType: values?.applicationType,
          approvals: approvalSet?.map(it => it?.id) || [],
          approvalMode: appData?.attributes?.approvalMode,
          approvedDate: appData?.attributes?.approvedDate ? moment(appData?.attributes?.approvedDate).format('YYYY-MM-DD') : null,
          category: appData?.attributes?.category,
          company: appData?.attributes?.company?.data?.id,
          description: appData?.attributes?.description,
          // start: appData?.attributes?.start ? moment(appData?.attributes?.end).format('YYYY-MM-DD') : null,
          // end: appData?.attributes?.end ? moment(appData?.attributes?.end).format('YYYY-MM-DD') : null,
          invoice: appData?.attributes?.invoice?.data?.id,
          involvement: appData?.attributes?.involvement,
          isGovernmentWork: appData?.attributes?.isGovernmentWork,
          isPaymentSkipped: appData?.attributes?.isPaymentSkipped,
          notes: appData?.attributes?.notes?.data?.map(it => it?.id),
          paymentSkipReason: appData?.attributes?.paymentSkipReason,
          payments: [],
          progress: appData?.attributes?.progress,
          project: appData?.attributes?.project?.data?.id,
          requiredDocuments: requiredDocCopy,
          serviceProviders: appData?.attributes?.serviceProviders?.data?.map(it => it?.id),
          serviceType: appData?.attributes?.serviceType,
          status: appData?.attributes?.status,
          spIsAffected: appData?.attributes?.spIsAffected,
          submittedDate: appData?.attributes?.submittedDate ? moment(appData?.attributes?.submittedDate).format('YYYY-MM-DD') : null,
          title: appData?.attributes?.title,
        },
      },
      refetchQueries: 'active',
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Application duplicated')
        setIsModalOpen(false)
        setTriggerRefetch(true)
      },
    })
  }
  const applicationTypeOptions = appTypes?.map(it => ({
    label: it?.attributes?.title,
    value: it?.id,
  })) as SelectOptionsType[]
  const applicationApprovalsOptions = [
    {
      label: 'Duplicate approvals',
      value: 'true'
    }
  ]
  const initialValues = {
    applicationType: appData?.attributes?.applicationType?.data?.id,
    applicationApprovals: []
  }
  if (appDataLoading) return <WebsiteLoader />
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Duplicate Application'}
      </Text>
      <Form layout={'vertical'} initialValues={initialValues} onFinish={onDuplicate}>
        <Row style={{ margin: '20px 0' }}>
          <Col span={24}>
            <Text size={'sm'} weight={'w400'}>
              {`You are going to duplicate application with number: `}
            </Text>
            <Paragraph size={'lg'} weight={'w700'}>{getTitleWithIndex(appData?.attributes?.title, appData?.attributes?.duplicationIndex)}</Paragraph>
            <Paragraph>{'Before saving you can change the following application settings:'}</Paragraph>
            <FormItem loading={appTypesLoading} label={'Application type'} name={'applicationType'} type={'select'} selectOptions={applicationTypeOptions} />
            <FormItem required={false} label={'Application approvals'} name={'applicationApprovals'} checkboxColSpan={12} type={'checkbox-group'} selectOptions={applicationApprovalsOptions} />
          </Col>
        </Row>
        <Row justify={'end'}>
          <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
          <Button
            text={'Duplicate'}
            btnType={'primary'}
            htmlType={'submit'}
            // onClick={onDuplicate}
            loading={loading || appDataLoading}
          />
        </Row>

      </Form>
    </>
  )
}
export default DuplicateApplication
