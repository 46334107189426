import classnames from 'classnames'
import { FC } from 'react'
import './TitleTag.less'
import Typography from '../typography/Typography'

const { Text } = Typography
type Props = {
  text: Maybe<string>
  fontSize?: 'md' | 'sm' | 'xs'
  accented?: boolean
}
const TitleTag:FC<Props> = ({text, fontSize = 'xs', accented = false}) => {
  const classes = classnames('title-tag', {
    'accented': accented
  })
  return (
    <div className={classes}>
      <Text weight={'w700'} size={fontSize}>{text}</Text>
    </div>
  )
}

export default TitleTag
