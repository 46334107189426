import { FC, SVGProps } from 'react'
import classnames from 'classnames'
import './icon-styles.less'

import { ReactComponent as AgentApplicationsOverview } from './images/agent-applications-overview.svg'
import { ReactComponent as AgentInfoOverview } from './images/agent-info-overview.svg'
import { ReactComponent as AgentTasksOverview } from './images/agent-tasks-overview.svg'
import { ReactComponent as AgentTemplate } from './images/agent-template.svg'
import { ReactComponent as AppCreateClose } from './images/app-create-close.svg'
import { ReactComponent as ArrowBack } from './images/arrow-back.svg'
import { ReactComponent as ArrowUpDown } from './images/arrow-down-up.svg'
import { ReactComponent as ArrowLeft } from './images/arrow-left.svg'
import { ReactComponent as ArrowRight } from './images/arrow-right.svg'
import { ReactComponent as ArrowScrollUp } from './images/arrow-scroll-up.svg'
import { ReactComponent as Bin } from './images/bin.svg'
import { ReactComponent as BookmarkStarActive } from './images/bookmark-star-fill.svg'
import { ReactComponent as BookmarkStarPrimary } from './images/bookmark-star-primary.svg'
import { ReactComponent as BookmarkStar } from './images/bookmark-star.svg'
import { ReactComponent as Calendar } from './images/calendar-date-picker.svg'
import { ReactComponent as Checked } from './images/checked.svg'
import { ReactComponent as NotificationClip } from './images/clip-notification-1.svg'
import { ReactComponent as NotificationClip2 } from './images/clip-notification-2.svg'
import { ReactComponent as ClipProvidersEmpty } from './images/clip-providers-empty.svg'
import { ReactComponent as ClipProviders } from './images/clip-providers.svg'
import { ReactComponent as Clip } from './images/clip.svg'
import { ReactComponent as ClipperInfoCard } from './images/clipper-info-card.svg'
import { ReactComponent as CloseModal } from './images/close-modal.svg'
import { ReactComponent as CloseNotification } from './images/close-notification.svg'
import { ReactComponent as ManagerCompanies } from './images/company.svg'
import { ReactComponent as AppCreateTick } from './images/create-app-tick.svg'
import { ReactComponent as Cross } from './images/cross.svg'
import { ReactComponent as AppCreateDetails } from './images/details.svg'
import { ReactComponent as DocSheet } from './images/doc-sheet.svg'
import { ReactComponent as Doc } from './images/doc.svg'
import { ReactComponent as DoubleGrayDots } from './images/double-gray-dots.svg'
import { ReactComponent as DownArrow } from './images/down-arrow.svg'
import { ReactComponent as Download } from './images/download.svg'
import { ReactComponent as Logo } from './images/easetech-logo.svg'
import { ReactComponent as Envelop } from './images/envelop.svg'
import { ReactComponent as Esc } from './images/esc.svg'
import { ReactComponent as ExpandZoom } from './images/expand-zoom.svg'
import { ReactComponent as SidebarGovernment } from './images/government-sidebar.svg'
import { ReactComponent as GovernmentTemplate } from './images/government-template.svg'
import { ReactComponent as GridViewSwitcher } from './images/grid-view-switcher.svg'
import { ReactComponent as ImageGray } from './images/image-gray.svg'
import { ReactComponent as NotificationImage } from './images/image-notification.svg'
import { ReactComponent as AppCreateLocation } from './images/location.svg'
import { ReactComponent as LogoBlue } from './images/logo-blue.svg'
import { ReactComponent as LogoCompanyNameDark } from './images/ease-tech-company-name.svg'
import { ReactComponent as ClockCircle } from './images/circle-clock.svg'
import {
  ReactComponent as Easetech,
  ReactComponent as LogoCompanyName,
} from './images/ease-tech-company-name-white.svg'
import { ReactComponent as LogoDarkBlue } from './images/logo-dark-blue.svg'
import { ReactComponent as LogoDarkBlueFlipped } from './images/easetech-logo-flipped.svg'
import { ReactComponent as MagnifyingGlass } from './images/magnifying-glass.svg'
import { ReactComponent as MagnifyingGlassGray } from './images/magnifying-glass-gray.svg'
import { ReactComponent as ManagerReports } from './images/manager-reports.svg'
import { ReactComponent as ManagerMap } from './images/map-manager.svg'
import { ReactComponent as ProjectMarker } from './images/marker.svg'
import { ReactComponent as NoNotifications } from './images/no-notifications.svg'
import { ReactComponent as NotificationMenu } from './images/notification-menu.svg'
import { ReactComponent as Pencil } from './images/pencil.svg'
import { ReactComponent as Plus } from './images/plus.svg'
import { ReactComponent as PlusWhite } from './images/plus-white.svg'
import { ReactComponent as ProfessionalTemplate } from './images/professional-template.svg'
import { ReactComponent as ProfessionalsList } from './images/professionals-list.svg'
import { ReactComponent as ProjectInfo } from './images/project-info.svg'
import { ReactComponent as Refresh } from './images/refresh.svg'
import { ReactComponent as ServiceTemplate } from './images/service-template.svg'
import { ReactComponent as Settings } from './images/settings.svg'
import { ReactComponent as SidebarApplications } from './images/sidebar-applications.svg'
import { ReactComponent as SidebarBag } from './images/sidebar-bag.svg'
import { ReactComponent as SidebarBarChart } from './images/sidebar-bar-chart-sheet.svg'
import { ReactComponent as SidebarClipper } from './images/sidebar-clipper.svg'
import { ReactComponent as SidebarCompass } from './images/sidebar-compass.svg'
import { ReactComponent as SidebarFolder } from './images/sidebar-folder.svg'
import { ReactComponent as SidebarOverview } from './images/sidebar-overview.svg'
import { ReactComponent as SidebarProjects } from './images/sidebar-projects.svg'
import { ReactComponent as SidebarTickBox } from './images/sidebar-tick-box.svg'
import { ReactComponent as SidebarUserOverviewMain } from './images/sidebar-user-overview-main.svg'
import { ReactComponent as SmallWhiteTick } from './images/small-white-tick.svg'
import { ReactComponent as NotificationSmile } from './images/smile.svg'
import { ReactComponent as SuccessTick } from './images/success-tick.svg'
import { ReactComponent as TableViewSwitcher } from './images/table-view-switcher.svg'
import { ReactComponent as TickInfoCard } from './images/tick-info-card.svg'
import { ReactComponent as Unchecked } from './images/unchecked.svg'
import { ReactComponent as AppCreateUpload } from './images/upload.svg'

import { ReactComponent as YellowFolder } from './images/yellow-folder.svg'
import { ReactComponent as MapPinDrop } from './images/map-pin-drop.svg'
import { ReactComponent as LogoEasetechDark } from './images/logo_company_name_dark.svg'
import { ReactComponent as LogoEasetechWhite } from './images/logo_company_name_white.svg'
import { ReactComponent as WebsiteBenefit01 } from './website-icons/benefits-01.svg'
import { ReactComponent as WebsiteIconHexagon } from './website-icons/benefit-hexagon.svg'
import { ReactComponent as WebsiteServiceCardButton } from './website-icons/card-button.svg'
import { ReactComponent as WebsiteServiceArrowRight } from './website-icons/services-arrow-right.svg'
import { ReactComponent as WebsiteSliderHexButton } from './website-icons/hexagon-slider-button.svg'
import { ReactComponent as WebsiteSliderArrowRight } from './website-icons/slider-arrow-right.svg'
import { ReactComponent as WebsiteSliderArrowLeft } from './website-icons/slider-arrow-left.svg'
import { ReactComponent as CollapseExpand } from './website-icons/collapse-expand-icon.svg'
import { ReactComponent as FooterHexagonButton } from './website-icons/footer-soc-hexagon.svg'
import { ReactComponent as PopupBtn } from './website-icons/popup-button.svg'
import { ReactComponent as PopupClose } from './website-icons/popup-close.svg'
import { ReactComponent as SplitArrow } from './images/split-cart-arrow.svg'
import { ReactComponent as HexagonBtn } from './website-icons/hexagon-button.svg'
import { ReactComponent as HexagonArrow } from './website-icons/hexagonArrow.svg'
import { ReactComponent as HexagonProcess } from './website-icons/processItem.svg'
import { ReactComponent as HexagonProcessBadge } from './website-icons/processItemBedge.svg'
import { ReactComponent as dashedLine } from './website-icons/dashedLine.svg'
import { ReactComponent as Bell } from './images/bell.svg'
import { ReactComponent as WarningCircle } from './images/warning-circle.svg'
import { ReactComponent as CheckCircle } from './images/check-circle.svg'
import { ReactComponent as Eye } from './images/eye.svg'
import { ReactComponent as ExpandArrowDown } from './images/expand-arrow-down.svg'
import { ReactComponent as ExpandArrowUp } from './images/expand-arrow-up.svg'
import { ReactComponent as SliderSettings } from './images/sliders-settings.svg'
import { ReactComponent as ReadMoreArrowRight } from './website-icons/read-more-arrow-right.svg'
import { ReactComponent as PdfLogo } from './website-icons/pdf-logo.svg'
import { ReactComponent as ArrowRightOrange } from './website-icons/arrow-right-orange.svg'
import { ReactComponent as ResultItem } from './website-icons/result-item.svg'
import { ReactComponent as Building } from './images/building.svg'
import { ReactComponent as CheckedSquare } from './images/check-square.svg'
import { ReactComponent as Alarm } from './images/alarm-fill.svg'
import { ReactComponent as ApprovedDoc } from './images/list-check.svg'
import { ReactComponent as BurgerIcon } from './website-icons/burger-button.svg'

import { ReactComponent as RequestInfo } from './images/request-info-2.svg'
import { ReactComponent as At } from './images/at.svg'
import { ReactComponent as Display } from './images/display.svg'
import { ReactComponent as Briefcase } from './images/briefcase.svg'
import { ReactComponent as Question } from './images/question.svg'
import { ReactComponent as Transfer } from './images/box-arrow-in-right.svg'
import { ReactComponent as EnvelopOpen } from './images/envelope-open.svg'
import { ReactComponent as ChatSquare } from './images/chat-square.svg'

import { ReactComponent as Phone } from './images/phone.svg'
import { ReactComponent as Whatsapp } from './images/whatsapp.svg'
import { ReactComponent as ArrowUp90 } from './images/arrow-90deg-up.svg'
import { ReactComponent as DocPreview } from './images/doc-layout.svg'
import { ReactComponent as ClientCentric } from './images/client-centric.svg'

import { ReactComponent as Image } from './images/card-image.svg'
import { ReactComponent as PlusSm } from './images/plus-sm.svg'
import { ReactComponent as LampIdea } from './images/lamp-idea.svg'
import { ReactComponent as MoneyCircle } from './images/money-circle.svg'
import { ReactComponent as StarredAward } from './images/starred-award.svg'
import { ReactComponent as ThumbUp } from './images/thumb-up.svg'
import { ReactComponent as Timer } from './images/timer.svg'

// import { ReactComponent as RequestInfo } from './images/request-info.svg'

export type IconTypes =

  | 'arrow-up-90'
  | 'building'
  | 'checked-square'
  | 'eye'
  | 'result-item'
  | 'pdf-logo'
  | 'arrow-right-orange'
  | 'clock-circle'
  | 'warning-circle'
  | 'check-circle'
  | 'plus-white'
  | 'bell'
  | 'process-item'
  | 'process-badge'
  | 'dashed-line'
  | 'hexagon-arrow'
  | 'hexagon-btn'
  | 'split-arrow'
  | 'popup-btn'
  | 'popup-close'
  | 'footer-soc-btn'
  | 'project-info'
  | 'collapse-expand'
  | 'manager-reports'
  | 'agent-template'
  | 'professional-template'
  | 'government-template'
  | 'service-template'
  | 'sidebar-user-overview-main'
  | 'logo'
  | 'sidebar-folder'
  | 'sidebar-compass'
  | 'sidebar-clipper'
  | 'sidebar-tickBox'
  | 'sidebar-bag'
  | 'sidebar-barchart'
  | 'sidebar-overview'
  | 'sidebar-projects'
  | 'sidebar-applications'
  | 'sidebar-government'
  | 'sidebar-companies'
  | 'settings'
  | 'down'
  | 'notification-menu'
  | 'table-view-switcher'
  | 'grid-view-switcher'
  | 'magnifying-glass'
  | 'magnifying-glass-gray'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-scroll-up'
  | 'arrow-back'
  | 'read-more-arrow-right'
  | 'cross'
  | 'esc'
  | 'calendar'
  | 'no-notifications'
  | 'close-modal'
  | 'app-create-close'
  | 'app-create-details'
  | 'app-create-location'
  | 'app-create-upload'
  | 'app-create-tick'
  | 'refresh'
  | 'bin'
  | 'expand-zoom'
  | 'doc'
  | 'download'
  | 'image-gray'
  | 'clip'
  | 'plus'
  | 'success-tick'
  | 'tick-info-card'
  | 'clipper-info-card'
  | 'small-white-tick'
  | 'notification-image'
  | 'notification-clip'
  | 'notification-clip-2'
  | 'notification-smile'
  | 'checked'
  | 'unchecked'
  | 'clip-providers'
  | 'clip-providers-empty'
  | 'logo-blue'
  | 'logo-company-name'
  | 'logo-dark-blue'
  | 'logo-dark-blue-flipped'
  | 'doc-sheet'
  | 'yellow-folder'
  | 'double-gray-dots'
  | 'envelop'
  | 'pencil'
  | 'professionals-list'
  | 'arrow-up-down'
  | 'agent-info-overview'
  | 'agent-applications-overview'
  | 'agent-tasks-overview'
  | 'bookmark-star'
  | 'bookmark-star-active'
  | 'bookmark-star-primary'
  | 'close-notification'
  | 'project-marker'
  | 'manager-map'
  | 'easetech'
  | 'logo-company-name-dark'
  | 'logo-easetech-dark'
  | 'logo-easetech-white'
  | 'map-pin-drop'
  | 'website-benefit-01'
  | 'website-icon-hexagon'
  | 'website-service-card-button'
  | 'website-service-arrow-right'
  | 'website-slider-arrow-right'
  | 'website-slider-arrow-left'
  | 'website-slider-hex-button'
  | 'expand-arrow-down'
  | 'expand-arrow-up'
  | 'slider-settings'
  | 'alarm'
  | 'approved-doc'
  | 'request-info'
  | 'burger-icon'
  | 'at'
  | 'display'
  | 'briefcase'
  | 'question'
  | 'transfer'
  | 'envelop-open'
  | 'chat-square'
  | 'phone'
  | 'whatsapp'
  | 'doc-preview'
  | 'image'
  | 'plus-sm'
  | 'client-centric'
  | 'lamp-idea'
  | 'money-circle'
  | 'starred-award'
  | 'thumb-up'
  | 'timer'



const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  'plus-sm': PlusSm,
  'image': Image,
  'doc-preview': DocPreview,
  'arrow-up-90': ArrowUp90,
  'whatsapp': Whatsapp,
  'phone': Phone,
  'chat-square': ChatSquare,
  'envelop-open': EnvelopOpen,
  'burger-icon': BurgerIcon,
  'transfer': Transfer,
  'at': At,
  'display': Display,
  'briefcase': Briefcase,
  'question': Question,
  'request-info': RequestInfo,
  'approved-doc': ApprovedDoc,
  'alarm': Alarm,
  'building': Building,
  'checked-square': CheckedSquare,
  'result-item': ResultItem,
  'pdf-logo': PdfLogo,
  'arrow-right-orange': ArrowRightOrange,
  'expand-arrow-down': ExpandArrowDown,
  'expand-arrow-up': ExpandArrowUp,
  'slider-settings': SliderSettings,
  'eye': Eye,
  'clock-circle': ClockCircle,
  'warning-circle': WarningCircle,
  'check-circle': CheckCircle,
  'plus-white': PlusWhite,
  'bell': Bell,
  'process-item': HexagonProcess,
  'process-badge': HexagonProcessBadge,
  'dashed-line': dashedLine,
  'hexagon-arrow': HexagonArrow,
  'hexagon-btn': HexagonBtn,
  'split-arrow': SplitArrow,
  'popup-btn': PopupBtn,
  'popup-close': PopupClose,
  'footer-soc-btn': FooterHexagonButton,
  'collapse-expand': CollapseExpand,
  'project-info': ProjectInfo,
  'manager-reports': ManagerReports,
  'agent-template': AgentTemplate,
  'professional-template': ProfessionalTemplate,
  'government-template': GovernmentTemplate,
  'service-template': ServiceTemplate,
  'logo': Logo,
  'logo-dark-blue': LogoDarkBlue,
  'logo-dark-blue-flipped': LogoDarkBlueFlipped,
  'sidebar-folder': SidebarFolder,
  'sidebar-compass': SidebarCompass,
  'sidebar-clipper': SidebarClipper,
  'sidebar-tickBox': SidebarTickBox,
  'sidebar-bag': SidebarBag,
  'sidebar-barchart': SidebarBarChart,
  'sidebar-overview': SidebarOverview,
  'sidebar-projects': SidebarProjects,
  'sidebar-applications': SidebarApplications,
  'sidebar-government': SidebarGovernment,
  'sidebar-companies': ManagerCompanies,
  'settings': Settings,
  'notification-menu': NotificationMenu,
  'table-view-switcher': TableViewSwitcher,
  'grid-view-switcher': GridViewSwitcher,
  'magnifying-glass': MagnifyingGlass,
  'magnifying-glass-gray': MagnifyingGlassGray,
  'down': DownArrow,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-scroll-up': ArrowScrollUp,
  'read-more-arrow-right': ReadMoreArrowRight,
  'cross': Cross,
  'arrow-back': ArrowBack,
  'esc': Esc,
  'calendar': Calendar,
  'no-notifications': NoNotifications,
  'close-modal': CloseModal,
  'app-create-close': AppCreateClose,
  'app-create-details': AppCreateDetails,
  'app-create-location': AppCreateLocation,
  'app-create-upload': AppCreateUpload,
  'app-create-tick': AppCreateTick,
  'refresh': Refresh,
  'bin': Bin,
  'expand-zoom': ExpandZoom,
  'doc': Doc,
  'download': Download,
  'image-gray': ImageGray,
  'clip': Clip,
  'plus': Plus,
  'success-tick': SuccessTick,
  'tick-info-card': TickInfoCard,
  'clipper-info-card': ClipperInfoCard,
  'small-white-tick': SmallWhiteTick,
  'notification-image': NotificationImage,
  'notification-clip': NotificationClip,
  'notification-clip-2': NotificationClip2,
  'notification-smile': NotificationSmile,
  'checked': Checked,
  'unchecked': Unchecked,
  'clip-providers': ClipProviders,
  'clip-providers-empty': ClipProvidersEmpty,
  'logo-blue': LogoBlue,
  'logo-company-name': LogoCompanyName,
  'logo-company-name-dark': LogoCompanyNameDark,
  'doc-sheet': DocSheet,
  'yellow-folder': YellowFolder,
  'double-gray-dots': DoubleGrayDots,
  'envelop': Envelop,
  'pencil': Pencil,
  'professionals-list': ProfessionalsList,
  'arrow-up-down': ArrowUpDown,
  'agent-info-overview': AgentInfoOverview,
  'agent-applications-overview': AgentApplicationsOverview,
  'agent-tasks-overview': AgentTasksOverview,
  'sidebar-user-overview-main': SidebarUserOverviewMain,
  'bookmark-star': BookmarkStar,
  'bookmark-star-active': BookmarkStarActive,
  'bookmark-star-primary': BookmarkStarPrimary,
  'close-notification': CloseNotification,
  'project-marker': ProjectMarker,
  'manager-map': ManagerMap,
  'easetech': Easetech,
  'map-pin-drop': MapPinDrop,
  'logo-easetech-dark': LogoEasetechDark,
  'logo-easetech-white': LogoEasetechWhite,
  'website-benefit-01': WebsiteBenefit01,
  'website-icon-hexagon': WebsiteIconHexagon,
  'website-service-card-button': WebsiteServiceCardButton,
  'website-service-arrow-right': WebsiteServiceArrowRight,
  'website-slider-hex-button': WebsiteSliderHexButton,
  'website-slider-arrow-left': WebsiteSliderArrowLeft,
  'website-slider-arrow-right': WebsiteSliderArrowRight,
  'client-centric': ClientCentric,
  'lamp-idea': LampIdea,
  'money-circle': MoneyCircle,
  'starred-award': StarredAward,
  'thumb-up': ThumbUp,
  'timer': Timer,
} as const

type IconColor = 'primary-blue' | 'gray' | 'light-gray' | 'white' | 'red' | 'white-stroke' | 'primary-blue-stroke'
type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconTypes, color?: IconColor }

const SvgIcon: FC<SvgIconProps> = ({ type, color, className, ...svgProps }) => {
  const Icon = icons[type] ?? null
  const classes = classnames('svg-icon', color, className)
  return Icon && <Icon {...svgProps} className={classes} />
}

export { SvgIcon }


