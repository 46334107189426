import FormItem from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useApplicationQuery, useConfirmDeliveryApprovalItemMutation } from '@/graphql'
import { Checkbox, Form, message, Row, Skeleton } from 'antd'
import { useForm } from 'antd/es/form/Form'
import moment from 'moment'
import { FC, useState } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text } = Typography

const DeliveryNote: FC = () => {
  const [form] = useForm()
  const { setIsModalOpen, currentApplicationId, currentApprovalItemId } = useGeneralContext()
  const [confirmDelivery, { loading }] = useConfirmDeliveryApprovalItemMutation()

  const [isDisabled, setIsDisabled] = useState(true)
  const { data, loading: appDataLoading } = useApplicationQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentApplicationId },
  })
  const application = data?.application?.data as ApplicationEntity
  const currentApprovalItem = application?.attributes?.approvals?.data?.find(it => it?.id === currentApprovalItemId) as ApprovalEntity
  const currentApprovalItemCompany = currentApprovalItem?.attributes?.company?.data?.attributes?.name

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    form.submit()
    const values: { deliveryDate: Date, deliveryNotes: string } = form?.getFieldsValue()
    await confirmDelivery({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          applicationId: currentApplicationId,
          approvalItemId: currentApprovalItemId,
          deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD'),
          deliveryNotes: values.deliveryNotes,
        },
      },
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Delivery note changed successfully.')
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }

  const initialValues = {
    deliveryDate: currentApprovalItem?.attributes?.deliveryDate ? moment(currentApprovalItem?.attributes?.deliveryDate) : null,
    deliveryNotes: currentApprovalItem?.attributes?.deliveryNotes,
  }
  const onEdit = () => {
    setIsDisabled(false)
  }
  return appDataLoading ? (
    <>
      <Skeleton active={true} paragraph={{ rows: 1, width: '100%' }} />
      <Skeleton active={true} paragraph={{ rows: 5 }} />
    </>
  ) : (
    <>
      <FormItemDivider marginBottom={24} marginTop={18} title={'Delivery note'}
                       subTitle={`Delivery note for ${currentApprovalItemCompany}`}
                       extra={<Button btnType={'icon'} icon={<SvgIcon type={'pencil'} />} bordered onClick={onEdit} />}
      />
      <Form initialValues={initialValues} layout={'vertical'} form={form}>
        <Row align={'middle'}>
          <Checkbox checked disabled>
            <Text>{'Delivery confirmed'}</Text>
          </Checkbox>
        </Row>
        <FormItemDivider />
        <FormItem isShowDisabledDates={false} name={'deliveryDate'} type={'date-picker'} label={'Delivery date'} readOnly={isDisabled} />
        <FormItem name={'deliveryNotes'} type={'textarea'} label={'Delivery notes'} placeholder={'Enter delivery notes'} readOnly={isDisabled} />

      </Form>

      <Row justify={'end'}>
        <Button text={'Close'} btnType={'text'} onClick={onCancel} loading={loading} />
        <Button text={'Save changes'} btnType={'primary'} onClick={onConfirm} loading={loading} disabled={isDisabled} />
      </Row>
    </>
  )
}
export default DeliveryNote
